import React from "react";
import { Row, Col } from "react-bootstrap";
import Logo from "../../../images/partners/Symbox.png";

const symbox = () => {
  return (
    <div className="partner-detail-card">
      <Row>
        <div className="top-section">
          <div>
            <img className="img-fluid" src={Logo} alt="PartnerImage" />
          </div>
          <div className="padding-add">
            <h3>Symbox</h3>
            <div className="lbl-ptnrn">Professional Services Partner</div>
            <div>
              <a href="https://www.symbox.com" target="_blank">
                Visit Website
              </a>{" "}
              <span></span>
            </div>
          </div>
        </div>
      </Row>
      <Row>
        <Col>
          <div className="mt-3">
            <h4>About Symbox </h4>
            <p>
              Symbox Digital Business Platform is a business process modelling,
              orchestration, automation and monitoring platform that is used
              especially in Telco, Media and Entertainment domain use cases.
              iTelaSoft has a dedicated BPM Professional Services team that
              supports organization to rapidly digitize their business use cases
              using Low Code application development.
            </p>
          </div>
          <div className="mt-3">
            <h4>Focus Areas</h4>
            <div className="p"><span className="vdivide">-{" "}</span>Business process modelling</div>
            <div className="p"><span className="vdivide">-{" "}</span>Automation services</div>
          </div>
          <div className="mt-3">
            <h4>Operating Countries</h4>
            <p>UK <span className="vdivide">|</span> EMEA</p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default symbox;
